import { createBrowserHistory } from 'history';
import Handlebars from 'handlebars';
import qs from 'qs';
import 'waypoints/lib/noframework.waypoints';

const history = createBrowserHistory();

(function($, window, document) {
  $.fn.infiniteScroll = function(options) {
    options = $.extend(
      {
        autoFetchThreshold: 0,
        resetFetchedCount: true,
        ajaxRestCallbacks: {},
        callbackEntityAdded: null,
        callbackEntitiesAdded: null,
        paginationTriggerPrevious: '#paginator-trigger-previous',
        paginationTriggerNext: '#paginator-trigger-next',
      },
      options,
    );

    this.each(function() {
      let $this = $(this);
      let $paginators = $this.find('.paginator');
      let $paginator_previous = $paginators.filter('.paginator-previous');
      let $paginator_next = $paginators.filter('.paginator-next');
      let $entities = $this.find('.entities');
      let $entities_templateEntity = $entities.find('.template-entity');
      let $entities_templateNoEntity = $entities.find('.template-no-entity');
      let fixedHeaderHeight = parseInt($this.data('fixed-header-height')) || 0;

      let $paginator_trigger_previous = $(options.paginationTriggerPrevious);
      let $paginator_trigger_next = $(options.paginationTriggerNext);

      let queryStringCache = null;
      let updateOffsetTimer = null;
      let updateWaypointTimer = null;

      let $element_showMore = $this.find('.show-more-entity');
      let autoFetch = {
        fetchedCount: 0,
        threshold: parseInt(options.autoFetchThreshold),
        resetFetchedCount: options.resetFetchedCount,
      };

      let templateEntity = Handlebars.compile($entities_templateEntity.html());
      $entities_templateEntity.remove();

      let templateNoEntity = null;
      if ($entities_templateNoEntity.length > 0) {
        templateNoEntity = Handlebars.compile($entities_templateNoEntity.html());
        $entities_templateNoEntity.remove();
      }

      let $paginator_triggers = $();
      if (!$paginator_trigger_previous.is(':empty')) {
        $paginator_triggers.add($paginator_trigger_previous);
      }
      if (!$paginator_trigger_next.is(':empty')) {
        $paginator_triggers.add($paginator_trigger_next);
      }

      // URL Query String
      var queryString_methods = {
        getQueryString: function() {
          var queryString = qs.parse(history.location.search.slice(1));
          $.extend(queryString, {
            offset: parseInt(queryString.offset),
            limit: parseInt(queryString.limit),
          });
          return queryString;
        },
        incrementOffset: function() {
          queryStringCache.offset++;
          queryString_methods.updateHistory();
          return true;
        },
        decrementOffset: function() {
          queryStringCache.offset--;
          queryString_methods.updateHistory();
          return true;
        },
        updateHistory: function() {
          if (updateOffsetTimer !== null) {
            clearTimeout(updateOffsetTimer);
            updateOffsetTimer = null;
          }
          updateOffsetTimer = setTimeout(function() {
            history.replace('?' + qs.stringify(queryStringCache));
            updateOffsetTimer = null;
          }, 150);
        },
      };

      // Entities
      var entities_methods = {
        createWaypoint: function($entity, fixedHeaderHeight = 0) {
          var waypoint = new Waypoint({
            element: $entity[0],
            handler: function(direction) {
              if (direction == 'up') {
                queryString_methods.decrementOffset();
              } else {
                // direction == 'down'
                queryString_methods.incrementOffset();
              }
            },
            offset: function() {
              return -this.element.clientHeight + fixedHeaderHeight;
            },
          });
          $entity.data('waypoint', waypoint);
          return waypoint;
        },
        destroyWaypoint: function($entity) {
          var waypoint = $entity.data('waypoint');
          waypoint.destroy();
        },
        prependEntity: function($entity) {
          let $firstEntity = $entities.children('.entity:first');
          if ($firstEntity.length === 0) {
            $entities.append($entity);
          } else {
            $firstEntity.before($entity);
          }

          // Decrement the offset of the new entity since this is to be adjusted by Waypoint on creation automatically
          queryString_methods.decrementOffset();
          entities_methods.createWaypoint($entity, fixedHeaderHeight);
        },
        appendEntity: function($entity) {
          if ($entities.children('.ignore-entity-footer').length != 0) {
            $entities.children('.ignore-entity-footer').before($entity);
          } else {
            $entities.append($entity);
          }
          entities_methods.createWaypoint($entity, fixedHeaderHeight);
        },
      };

      // Paginators
      var paginators_methods = {
        stateLoading: function($paginator, loading) {
          if (loading) {
            $paginator.addClass('loading');
          } else {
            $paginator.removeClass('loading');
          }
        },
        createWaypoint: function($paginator, paginatorType) {
          var waypointOptions = {};

          if (paginatorType === 'up' && $paginator_trigger_previous.length !== 0) {
            // Create the waypoint object, and store the instance in data-waypoint
            var waypoint = new Waypoint(
              $.extend(true, {}, waypointOptions, {
                element: $paginator_trigger_previous[0],
                handler: function(direction) {
                  if (direction === 'up') {
                    paginators_methods.destroyWaypoint($paginator);

                    let beforeHeight = $this.height();
                    let currentPosition = $(document).scrollTop();

                    paginators_methods.stateLoading($paginator, true);

                    let afterHeight = $this.height();
                    let scrollPosition = afterHeight - beforeHeight + currentPosition;
                    $(document).scrollTop(scrollPosition);

                    $paginator.trigger('submit');
                  }
                },
                offset: function() {
                  // When bottom of element hits top of viewport
                  return -this.element.clientHeight + fixedHeaderHeight;
                },
              }),
            );

            $paginator.data('waypoint', waypoint);
          } else if (paginatorType === 'down' && $paginator_trigger_next.length !== 0) {
            // Create the waypoint object, and store the instance in data-waypoint
            var waypoint = new Waypoint(
              $.extend(true, {}, waypointOptions, {
                element: $paginator_trigger_next[0],
                handler: function(direction) {
                  if (direction === 'down') {
                    paginators_methods.destroyWaypoint($paginator);
                    paginators_methods.stateLoading($paginator, true);
                    $paginator.trigger('submit');
                  }
                },
                offset: '100%', // When top of element hits bottom of viewport
              }),
            );

            $paginator.data('waypoint', waypoint);
          }
        },
        destroyWaypoint: function($paginator) {
          var waypoint = $paginator.data('waypoint');
          waypoint.destroy();
        },
        getOffset: function($paginator) {
          var $offset = $paginator.find('input[name="offset"]'),
            offset = parseInt($offset.val());
          return offset;
        },
        setOffset: function($paginator, offset) {
          var $offset = $paginator.find('input[name="offset"]'),
            offset = parseInt(offset) || 0;
          $offset.val(offset);
          return true;
        },
        getLimit: function($paginator) {
          var $limit = $paginator.find('input[name="limit"]'),
            limit = parseInt($limit.val());
          return limit;
        },
        setLimit: function($paginator, limit) {
          var $limit = $paginator.find('input[name="limit"]'),
            limit = parseInt(limit);
          $limit.val(limit);
          return true;
        },
        increaseAutoFetchedCount: function() {
          autoFetch.fetchedCount++;
          return true;
        },
        setAutoFetchedCount: function(count) {
          autoFetch.fetchedCount = count;
          return true;
        },
        getAutoFetchedCount: function() {
          return parseInt(autoFetch.fetchedCount);
        },
        getAutoFetchThreshold: function() {
          return parseInt(autoFetch.threshold);
        },
        shouldAutoFetch: function() {
          if (this.getAutoFetchThreshold() == 0) {
            return true;
          }

          if (this.getAutoFetchedCount() < this.getAutoFetchThreshold()) {
            return true;
          }

          return false;
        },
        displayShowMore: function() {
          $element_showMore.removeClass('hidden');
        },
        hideShowMore: function() {
          $element_showMore.addClass('hidden');
        },
        shouldResetCount: function() {
          return autoFetch.resetFetchedCount;
        },
      };

      // When form submission returns success, process the response data appropriately.
      $paginator_previous.ajaxRestForm({
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,
        callbacks: $.extend(true, {}, options.ajaxRestCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            // Callback ajaxRestCallbacks.success()
            if (typeof options.ajaxRestCallbacks.success === "function") {
              options.ajaxRestCallbacks.success.bind($this[0])(jqXHR);
            }

            // Declare some variables
            var oldLimit = paginators_methods.getLimit($paginator_previous),
              oldOffset = paginators_methods.getOffset($paginator_previous),
              newLimit = Math.min(oldLimit, oldOffset),
              newOffset = Math.max(oldOffset - oldLimit, 0),
              entities = jqXHR.responseJSON.entities.reverse();
            let beforeHeight = $this.height();

            let $entities = [];

            // Reverse and prepend the entities
            entities.forEach(function(data) {
              var renderedHtml = templateEntity(data),
                $entity = $(renderedHtml);
              entities_methods.prependEntity($entity);

              // Callback callbackEntityAdded()
              if (typeof options.callbackEntityAdded === "function") {
                options.callbackEntityAdded.bind($this[0])(jqXHR);
              }

              $entities.push($entity);
            });

            // Callback callbackEntitiesAdded()
            if (typeof options.callbackEntitiesAdded === "function") {
              options.callbackEntitiesAdded.bind($this[0])(jqXHR);
            }

            let currentPosition = $(document).scrollTop();

            // If what we get is less than our desired limit (for "paginator-next"), or if old offset is 0 (for "paginator-previous")
            if (entities.length < oldLimit || oldOffset == 0) {
              let paginatorPreviousHeight = $paginator_previous.outerHeight();
              paginators_methods.stateLoading($paginator_previous, false);

              let paginatorPrevious_topRelativeToViewport = $paginator_previous[0].getBoundingClientRect().top;
              $(document).scrollTop(currentPosition + paginatorPrevious_topRelativeToViewport);
            } else {
              paginators_methods.setOffset($paginator_previous, newOffset);
              paginators_methods.setLimit($paginator_previous, newLimit);
              paginators_methods.createWaypoint($paginator_previous, 'up');

              let paginatorPrevious_topRelativeToViewport = $paginator_previous[0].getBoundingClientRect().top;
              $(document).scrollTop(currentPosition + paginatorPrevious_topRelativeToViewport);
            }

            // All necessary waypoints have been initialized.  We should now refresh all so event callbacks can start coming.
            Waypoint.refreshAll();

            let afterHeight = $this.height();
            let scrollPosition = afterHeight - beforeHeight + currentPosition;
            $(document).scrollTop(scrollPosition);
          },
        }),
      });

      // When form submission returns success, process the response data appropriately.
      $paginator_next.ajaxRestForm({
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,
        callbacks: $.extend(true, {}, options.ajaxRestCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            // Callback ajaxRestCallbacks.success()
            if (typeof options.ajaxRestCallbacks.success === "function") {
              options.ajaxRestCallbacks.success.bind($this[0])(jqXHR);
            }

            // Declare some variables
            var oldLimit = paginators_methods.getLimit($paginator_next),
              oldOffset = paginators_methods.getOffset($paginator_next),
              newLimit = oldLimit,
              newOffset = oldOffset + oldLimit,
              entities = jqXHR.responseJSON.entities;

            // Append the entities
            if (entities.length > 0) {
              entities.forEach(function(data) {
                var renderedHtml = templateEntity(data),
                  $entity = $(renderedHtml);
                entities_methods.appendEntity($entity);

                // Callback callbackEntityAdded()
                if (typeof options.callbackEntityAdded === "function") {
                  options.callbackEntityAdded.bind($this[0])(jqXHR);
                }
              });
            } else {
              if (templateNoEntity !== null) {
                var renderedHtml = templateNoEntity(),
                  $entity = $(renderedHtml);
                entities_methods.appendEntity($entity);

                // Callback callbackEntityAdded()
                if (typeof options.callbackEntityAdded === "function") {
                  options.callbackEntityAdded.bind($this[0])(jqXHR);
                }
              }
            }

            paginators_methods.setOffset($paginator_next, newOffset);
            paginators_methods.increaseAutoFetchedCount();

            if ( entities.length >= oldLimit) {
              if(! paginators_methods.shouldAutoFetch()) {
                paginators_methods.displayShowMore();
                paginators_methods.stateLoading($paginator_next, false);
                return;
            	}
            } else {
              paginators_methods.hideShowMore();
              paginators_methods.stateLoading($paginator_next, false);
              return;
            }

            paginators_methods.createWaypoint($paginator_next, 'down');

            // All necessary waypoints have been initialized.  We should now refresh all so event callbacks can start coming.
            Waypoint.refreshAll();
          },
        }),
      });

      // Updating waypoint positions on window resize
      $(window).on('resize', function() {
        if (updateWaypointTimer !== null) {
          clearTimeout(updateWaypointTimer);
          updateWaypointTimer = null;
        }
        updateWaypointTimer = setTimeout(function() {
          Waypoint.refreshAll();
          updateWaypointTimer = null;
        }, 500);
      });

      // Create waypoint instances for each entity
      $entities.find('.entity').each(function() {
        var $entity = $(this);
        entities_methods.createWaypoint($entity, fixedHeaderHeight);
      });

      // When offset is not 0, we want to tweak the scroll position to that item
      if (queryString_methods.getQueryString().offset != 0) {
        // First we must disable the native scrollRestoration behavior by the browser
        window.history.scrollRestoration = 'manual';

        // If offset value on load is not zero, adjust the user's scroll position to the position of the first preloaded entity, and resolve when that's done.
        var $firstEntity = $entities.children().first().length != 0 ? $entities.children().first() : $entities,
          entitiesScrollPosition = $firstEntity.offset().top;

        $(document).scrollTop(entitiesScrollPosition);
      }

      // Initialize the valid paginators by creating waypoints, and remove invalid paginators
      $paginators.each(function() {
        var $paginator = $(this),
          $paginator_limit = $paginator.find('input[name="limit"]'),
          limit = parseInt($paginator_limit.val()),
          directionTrigger = $paginator.data('direction-trigger');

        if (limit == 0) {
          paginators_methods.stateLoading($paginator, false);
        } else {
          // Initialize the paginator if it is valid
          paginators_methods.createWaypoint($paginator, directionTrigger);
        }
      });

      $element_showMore.find('.show-more-trigger').on('click', function() {
        paginators_methods.hideShowMore();
        paginators_methods.stateLoading($paginator_next, true);
        if (paginators_methods.shouldResetCount()) {
          paginators_methods.setAutoFetchedCount(0);
        }
        $paginator_next.trigger('submit');
      });

      // Obtain the query string once on load
      queryStringCache = queryString_methods.getQueryString();
    });

    return this;
  };
})(jQuery, window, document);
